import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const WorkshopComponent = () => {
	const workshops = require('../config/workshops.json');
	const imagePrefix = 'static/images/workshop/';
	function srcset(image, size, rows = 1, cols = 1) {
	  return {
	    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
	    srcSet: `${image}?w=${size * cols}&h=${
	      size * rows
	    }&fit=crop&auto=format&dpr=2 2x`,
	  };
	}
	
	
	return (
	<>
			<Card className='animated'>
				<CardContent style={{backgroundColor: 'aliceblue'}}>
				<CardHeader title="Workshops" subheader="I have provided workshops in different institutes like IIJ  Bombay , Fashion Technology Institute , Schools ,
				 Prince of Wales museum -Mumbai , IGMS Bhopal , UMSAS Patna etc. offline and online. Recently in 2022 I gave workshop in Bombay International School , Shishuvan School and Danji Devshi Kelvani Foundation
 Mumbai. Around 30 students from different classes and age group participate in each workshop class. I have been providing workshops like these from past 25 years.">
					        </CardHeader>
			<Grid container spacing={2}>
				
				{workshops.map((workshop, index) => {
					return (
						<Grid item xs={12}>
					<Typography variant="h6"> {workshop.venue} </Typography>
					<Typography variant="body" color="text.secondary"> {workshop.description} </Typography>

					<ImageList
				      variant="quilted"
				      cols={12}
				      rowHeight={121}
				    >
				      {workshop.images.map((item) => (
				        <ImageListItem key={item.image} cols={item.cols || 1} rows={item.rows || 1} onClick={()=>{
							window.open(require('../' + imagePrefix + item.image))
						}} sx={{cursor: 'pointer'}}>
				          <img
				            {...srcset(require('../' + imagePrefix + item.image), 121, item.rows, item.cols)}
				            alt={workshop.venue}
				            loading="lazy"
				          />
				        </ImageListItem>
				      ))}
				    </ImageList>
					</Grid>
					)
				})}
				
		    </Grid>
		    </CardContent>
				</Card>
		</>
		)
}

export default WorkshopComponent;