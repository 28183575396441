import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import MainComponent from './components/MainComponent';
import { CssBaseline } from '@mui/material';
import ProfileComponent from './components/ProfileComponent';
import WorkComponent from './components/WorkComponent';
import WelcomeComponent from './components/WelcomeComponent';
import AwardsComponent from './components/AwardsComponent';
import WorkshopComponent from './components/WorkshopComponent';
import ContactComponent from './components/ContactComponent';
import WelcomeLearnMoreComponent from './components/WelcomeLearnMoreComponent';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useEffect } from 'react';

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  return (
    <>
      <CssBaseline/>
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<MainComponent/>}>
            <Route path="profile" element={<ProfileComponent/>}/>
            <Route path="gallery" element={<WorkComponent/>}/>
            <Route path="awards" element={<AwardsComponent/>}/>
            <Route path="workshops" element={<WorkshopComponent/>}/>
            <Route path="contact" element={<ContactComponent/>}/>
            <Route path="artDetails" element={<WelcomeLearnMoreComponent/>}/>
            <Route index element={<WelcomeComponent/>}/>
            <Route path="*" element={<WelcomeComponent/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
