import { Card, CardContent, CardHeader, CardMedia, Grid } from '@mui/material';

const WorkComponent = () => {
	const awards = require('../config/work.json');
	const imagePrefix = 'static/images/work/';
	
	
	return (
	<>
			<Card className='animated'>
				<CardContent style={{backgroundColor: 'aliceblue'}}>
				<CardHeader title="Art Gallery" subheader="Please use Contact tab to order items." subheaderTypographyProps={{backgroundColor: "#aeeb3470"}}>
				</CardHeader>
			<Grid container spacing={2} >

				{awards.map((craft, idx) => (
					<Grid item xs={12} sm={6} key={idx}>
						<Card sx={{height: '100%'}}>
					      <CardMedia
					        component="img"
					        height="240"
					        sx = {{objectFit: "contain"}}
					        image={require('../' + imagePrefix + craft.image)}
					        alt="Art image"
					      />
					      <CardContent>
					        <CardHeader title={craft.heading} subheader={craft.description}>
					        </CardHeader>
					      </CardContent>
					    </Card>
			    	</Grid>
					))}
				
		    </Grid>
		    </CardContent>
				</Card>
		</>
		)
}

export default WorkComponent;