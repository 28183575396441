import { Card, CardContent, CardHeader, CardMedia, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CircleIcon from '@mui/icons-material/Circle';

const WelcomeLearnMoreComponent = () => {
	const colours = [
	{
		key: 'Pink',
		source: 'Rose Petals'
	},
{
		key: 'Brick Red',
		source: 'Gaur'
	},
{
		key: 'Saffron',
		source: 'Har-Singar'
	},
{
		key: 'Blue',
		source: 'Sikhait'
	},
{
		key: 'Green',
		source: 'Sem leaves (French Beans)'
	},
{
		key: 'Brown',
		source: 'Mehendi leaves'
	},
{
		key: 'Yellow',
		source: 'Turmeric'
	},
{
		key: 'Black',
		source: 'Carbon (soot)'
	},

		];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



	return (
			<>
						<Card sx={{textAlign: 'justify', backgroundColor: 'aliceblue'}} className='animated'>
					      
					      <CardContent> <CardMedia component="img" height="240" sx = {{objectFit: "contain", backgroundColor: '#9a2d32'}} image={require
					      ("../static/images/kamini-kaushal-4.jpeg")} alt="Profile Picture" /> <CardHeader title="All about the art"
					      subheader="Kamini Kaushal explains the art"> </CardHeader> <Divider light={true}/> <Grid item container>
					      <Grid item xs={12}>
					      	<Typography variant = "body" component='p'>
					      		Here is a video covering some stages of the process from a high level:
					      	</Typography>
					      	<div style={{textAlign: 'center'}}>
					      	<iframe width="70%" height="240" src="https://www.youtube.com/embed/Q5Jbiw-WIUM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					      	</div>
					      </Grid>
					      
					      <Grid item xs={6}> 

									      <Typography variant="h6"> Madhubani Papier Mache </Typography> <Typography
									      variant="body" color="text.secondary"> Madhubani is described as a craft city where number of crafts
									      are born and nurtured. Some of the main crafts of this place are Madhubani Painting, Madhubani Papier
									      Mache, Sikki craft, and Sujani Embroidery. Madhubani Papier Mache has its roots in ancient
									      Mithila. Since the golden era of Mithilanchal the craft has been practiced by Mithilanis to prepare
									      decorative items & portray different religious rituals. It was
									      significantly recognized in royal courts of Mithila. The art of Papier Mache Craft has been handed down from generation to generation. This craft reflects the cultural heritage of any family.
									      Some of the common examples of Paper Mache craft items are Bangle Box, Vases, Pots, Jars & Jewellery Items. Papier Mache Toys and Dolls are moulded on folk tradition which has continued to find
									      vibrant expressions in their work. This craft is light, durable and adaptable for making decorative
									      bowls, frames, masks, exotic beads, earrings, bangles, trinkets, containers, boxes for dry fruits, pot
									      for storing grains, jewellery and making panels too etc. Objects are made out of paper pulp and painted from outside with the ideas inspired
									      from Madhubani painting.

									        </Typography>
					        </Grid>

					        <Grid container item xs={6} direction='column' justifyContent='space-evenly' alignItems='flex-end'>
					        	<Grid item >
					        		<figure>
					        <img src={require("../static/images/beti-bacho-beti-padhao.jpg")} style={{width:'100%', height: 'auto'}} alt="Sources of color"/>
					        		<figcaption style={{textAlign: 'center'}}>Mahila vikas or beti bacho beti padhao</figcaption>
					        		</figure>
					        	</Grid>
					        	<Grid item >
					        		<figure>
					        <img src={require("../static/images/krishna.jpg")} style={{width:'100%', height: 'auto'}} alt="Sources of color"/>
					        		<figcaption style={{textAlign: 'center'}}>Describing childhood of krishna</figcaption>
					        		</figure>
					        	</Grid>
					        </Grid>




					        
					        
							 
							 
					        

					        <Grid item xs={12}>
					        <Typography variant="h6">
					        	Characteristics of Madhubani Papier Mache
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					        <List>
					        <ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					         In Madhubani Papier Mache creation of the object is more important than its surface
decoration. More concentration is put on shaping the item and paint it to make it look attractive.
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
							<ListItemText>
					         All the objects made by the artisans are totally handmade. They don’t use any types of
moulds in the process of object making, this is the reason because of which we rarely find any
type of similarity among the products. There has to be some degree of diversity
even among the same kind of product range. 
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
							<ListItemText>
					         The entire product is made by an artisan. Starting from thinking of the concept to the final
process of painting the product all is done if possible by the same artisan. There are no separate
groups to perform different tasks.
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
							<ListItemText>
					         In Madhubani Papier Mache most of the motifs are from Madhubani painting like
elephant, Kohbar Ghar for decoration purposes and when they do painting on the objects also
they paint trees, human faces that we can see in those paintings.
even among the same kind of product range. 
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
							<ListItemText>
					         They usually make utilitarian as well as ornamental products right from the beginning
now they have started making products like bangles, stands, mobile covers and products
according to the market demands.

							</ListItemText>
							</ListItem>
							</List>
					        </Typography>
					        </Grid>
					       <Grid item xs={6}>
					        <Typography variant="h6">
					        	Raw Materials Used
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					        Raw materials used in this craft are easily available at
home and at local market. Required materials for this craft
are:
					        <List>
					        <ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					         Newspaper or any other waste paper- The basic raw
material for this craft which is used to prepare paper pulp,
newspaper or any other waste paper can be used.
							</ListItemText>
							</ListItem>

							<ListItem> <ListItemIcon> <KeyboardArrowRightIcon/> </ListItemIcon> <ListItemText> Multani Mitti or Powder
							of the bark of Darad Maida tree – Initially, bark of Dard Maida tree was used to provide strength to the
							product and to stick paper pulp but now Dard Maida Tree is not available every were so Multani Mitti is
							used as it gives a better finish to the product. </ListItemText> </ListItem>

							<ListItem> <ListItemIcon> <KeyboardArrowRightIcon/> </ListItemIcon> <ListItemText> Water of Methi Seed- It
							is used for fragrance and water of methi seed helps in keeping it safe from moths and other insects and
							it produces good smell. 
	

							</ListItemText>
							</ListItem>

						
							
							</List>
					        </Typography>
					        </Grid>
					        <Grid container item xs={6} direction='column' justifyContent='space-evenly' alignItems='flex-end'>
					        	<Grid item >
					        		<figure>
					        		<img src={require("../static/images/raw-material.png")} style={{width:'100%', height: 'auto'}} alt="Raw Materials"/>
					        		<figcaption style={{textAlign: 'center'}}>Darad Maida & Miltani Mitti</figcaption>
					        		</figure>
					        	</Grid>

					        	<Grid item >
					        		<figure>
					        		<img src={require("../static/images/raw-material-2.png")} style={{width:'100%', height: 'auto'}} alt="Raw Materials"/>
					        		<figcaption style={{textAlign: 'center'}}>Raw Material used in the process of making Product</figcaption>
					        		</figure>
					        	</Grid>
					        </Grid>

					        <Grid item xs={12}>
					        <Typography variant="h6">
					        	Amount of Materials used:
					        </Typography>

					        <Typography variant="body" color="text.secondary">
					        <List>
					        <ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					        	Pulp – 1 kg.
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
							<ListItemText>
					         	Bark of Dard Maida Tree -150 gm./Multani Mitti – 250 gm.

							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
							<ListItemText>
					        	Water of Methi seed.
							</ListItemText>
							</ListItem>
							</List>
					        </Typography>
					        </Grid>

					        <Grid item xs={6}>
					        <Typography variant="h6">
					        	Tools Used:
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					        	Basically it is a handmade product so
								machinery and other equipments in its
								manufacturing are not required. Only some
								household tools are needed:
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					        <List>
					        <ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					       		Khal Musal or Stone Slab and Wooden
Hammer- It is used for crushing paper and for
preparing paper pulp.
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					       		Brushes- For painting &Colouring.
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
										Pot – It is used for soaking the paper into the water.							</ListItemText>
							</ListItem>
							</List>
					        </Typography>
					        </Grid>

					        <Grid container item xs={6} direction='column' justifyContent='space-evenly' alignItems='flex-end'>
					        	<Grid item >
					        		<figure>
					        		<img src={require("../static/images/khal-musal-brushes.png")} style={{width:'100%', height: 'auto'}} alt="Khal Musal & Brushes"/>
					        		<figcaption style={{textAlign: 'center'}}>Khal Musal & Brushes</figcaption>
					        		</figure>
					        	</Grid>
					        </Grid>


					        <Grid item xs={6}>
					        <Typography variant="h6">
					        	Process
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					        <List>
					        <ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					         	Tear up the paper into 2-to-3-inch pieces.
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
							<ListItemText>
					       	 Immerse or dip the torn-up pieces of paper into water for 3-to-4 days. Change the water on daily basis.
							</ListItemText>
							</ListItem>

							<ListItem> <ListItemIcon> <KeyboardArrowRightIcon/> </ListItemIcon> <ListItemText> After soaking
							properly,it is taken out of the water and grind into fine pulp by beating with a small log (called as
							Mungri in Mithila) or in Khal- Musal .
							 </ListItemText>
							  </ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
							<ListItemText>
					        This way the pulp of paper is prepared. 
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
							<ListItemText>
					         When the mixture is ready then different
 shapes and forms are given on the basis of
 imaginations by hands only without the help
 of any moulds
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
							<ListItemText>
					         	Then following materials are added to the prepared pulp:
							</ListItemText>									
							</ListItem>
								<List sx={{ml: 5}}>
									<ListItem>
							<ListItemIcon>
					        		<CircleIcon sx={{fontSize: '0.7rem'}}/>
					        	</ListItemIcon>
							<ListItemText>
					          Bark of Dard Maida Tree/Multani Mitti.
							</ListItemText>
							</ListItem>

							<ListItem>
							<ListItemIcon>
					        		<CircleIcon sx={{fontSize: '0.7rem'}}/>
					        	</ListItemIcon>
							<ListItemText>
					           Water of Methi seeds.
							</ListItemText>
							</ListItem>
							

							

									</List>


							<ListItem> <ListItemIcon> <KeyboardArrowRightIcon/> </ListItemIcon> <ListItemText> When the mixture is
							ready, then different shapes and forms are given on the basis of imaginations by hands only without the
							help of any moulds. </ListItemText> </ListItem>

							<ListItem> <ListItemIcon> <KeyboardArrowRightIcon/> </ListItemIcon> <ListItemText> 
								After the products are prepared, they left behind for drying in the sun.
								 </ListItemText> </ListItem>

								 <ListItem> <ListItemIcon> <KeyboardArrowRightIcon/> </ListItemIcon> <ListItemText> 
								From the Mixture of paper pulp, multani mitti and methi powder product are made out of it and final craft is prepared after colouring.
								 </ListItemText> </ListItem>
								 	</List>




					        </Typography>
					        </Grid>
					        <Grid container item xs={6} direction='column' justifyContent='space-evenly' alignItems='flex-end'>
					        	<Grid item >
					        		<figure>
					        		<img src={require("../static/images/item-before-color.png")} style={{width:'100%', height: 'auto'}} alt="Item before coloring"/>
					        		<figcaption style={{textAlign: 'center'}}>Ganeshji & other items before coloring</figcaption>
					        		</figure>

					        		<figure>
					        		<img src={require("../static/images/radha-krishna-before-coloring.png")} style={{width:'100%', height: 'auto'}} alt="Item before coloring"/>
					        		<figcaption style={{textAlign: 'center'}}>Radha Krishna before coloring</figcaption>
					        		</figure>
					        	</Grid>
					        </Grid>

					         <Grid item xs={12}> <Typography variant="h6"> About Natural Colors </Typography> <Typography
					         variant="body" color="text.secondary"> Anciently, only natural colours have used for painting.
					         Natural colours have made with the help of different leaves and petals. Mainly following 8 colours
					         are used and they are collected from the following natural sources:
					         </Typography> 
					         <Table>
					         	<TableHead>
					         	<TableRow>
					         		<TableCell>
					         			Colour
					         		</TableCell>
					         		<TableCell>
					         			Source
					         		</TableCell>
					         		</TableRow>
					         	</TableHead>
					         	<TableBody>
					         		{colours.map((color, index) => 
					         			{ return (<StyledTableRow key={index}>
					         			<TableCell>
					         				{color.key}
					         			</TableCell>
					         			<TableCell>
					         				{color.source}
					         			</TableCell>
					         		</StyledTableRow>)})}
					         	</TableBody>
					         </Table>


					            </Grid> 

					         <Grid item xs={6}>
					        <Typography variant="h6">
					        	Range of products
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					        Initially traditional motifs were used to make products so decorative items and products for
							household utility dominated the production like:
					        <List>
					        <ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					         Doli
							</ListItemText>
							</ListItem>
							<ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					         Kothi- To store food grains
							</ListItemText>
							</ListItem>
							<ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					         Small Box- store dry products and food grains also.
							</ListItemText>
							</ListItem>
							</List>
							Traditional Motifs-
							<List>
					        <ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					         Marwa- It’s a hut shaped form. Presence of this motif is very important at the time of
marriages in Bihar
							</ListItemText>
							</ListItem>
							<ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					         Dulha-Dulhan (Bride and Bridegroom)
							</ListItemText>
							</ListItem>
							<ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					         Hathi Jannak Dala - It is a traditional motif used to welcome baaraat in marriages. It is
					         also used by bride to worship goddess Gauri after marriage (Sakar).
							</ListItemText>
							</ListItem>

							<ListItem>
					        <ListItemIcon>
					        		<KeyboardArrowRightIcon/>
					        	</ListItemIcon>
					        <ListItemText>
					         Nativity Set
							</ListItemText>
							</ListItem>
							</List>
							But with the change in consumer taste and demand they started making decorative as well
							as utility products for daily use.
					        </Typography>
					        </Grid>
					        <Grid container item xs={6} direction='column' justifyContent='space-evenly' alignItems='flex-end'>
					        	<Grid item >
					        		<figure>
					        		<img src={require("../static/images/marba.jpg")} style={{width:'100%', height: 'auto'}} alt="Marwa"/>
					        		<figcaption style={{textAlign: 'center'}}>Marwa</figcaption>
					        		</figure>
					        		<figure>
					        		<img src={require("../static/images/nativity-set.jpg")} style={{width:'100%', height: 'auto'}} alt="Nativity Set"/>
					        		<figcaption style={{textAlign: 'center'}}>Nativity Set</figcaption>
					        		</figure>
					        	</Grid>
					        </Grid>

					        
					        		
					        	

					        <Grid item xs={6}>
					        <Typography variant="h6">
					        	Decorative Products
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					        It includes Wall Hangings, Masks, Flower Vase, Photo Frames apart from the traditional motifs.
					        </Typography>

					        
					        	
					        <Typography variant="h6">
					        	Utility Products
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					        Pen stands, bangles and bangle boxes, necklaces as well as household utility products.
<Divider light = {true}/>
					        Price range of these products start from Rs.50- Rs. 20000
It means that these products are not only for niche market, ordinary person can also afford
it.
<Divider light = {true}/>
One very important fact relevant to this craft is that it is mainly performed by village
women. Initially there was least support from other male members of family so the existence of
this craft was also in trouble but later it was supported by DCH and hence the craft received
worldwide exposure which resulted in an increase in the income of the artisans. Now the women
are self dependent and the male members are also assisting them in the marketing of this craft.
Papier Mache products are completely recyclable and storage of this craft is also very
convenient Artisans store it inside the house at any safe and dry place. 
					        </Typography>
					        </Grid>
					        <Grid container item xs={6} direction='column' justifyContent='space-evenly' alignItems='flex-end'>
					        	<Grid item >
					        		<figure>
					        		<img src={require('../static/images/decorative-products.jpg')} style={{width:'100%', height: 'auto'}} alt="Wall Hanging"/>
					        		<figcaption style={{textAlign: 'center'}}>Wall Hangings</figcaption>
					        		</figure>
					        		<figure>
					        		<img src={require("../static/images/earrings.jpg")} style={{width:'100%', height: 'auto'}} alt="Earrings"/>
					        		<figcaption style={{textAlign: 'center'}}>Earrings</figcaption>
					        		</figure>

					        		<figure>
					        		<img src={require("../static/images/pen-stands.jpg")} style={{width:'100%', height: 'auto'}} alt="Earrings"/>
					        		<figcaption style={{textAlign: 'center'}}>Pen Stands</figcaption>
					        		</figure>
					        	</Grid>
					        </Grid>


					        

					      

					       </Grid>
					      </CardContent>
					    </Card>
		</>
		);
}

export default WelcomeLearnMoreComponent;