import { Card, CardContent, CardHeader, CardMedia, Divider, Grid, Typography } from '@mui/material';

const ProfileComponent = () => {
	return (
			<>
			
					<Grid item className='animated'>
						<Card sx={{textAlign: 'justify' , backgroundColor: 'aliceblue'}}>
					      
					      <CardContent>
					      	 <CardMedia
					        component="img"
					        height="240"
					        sx = {{objectFit: "contain", backgroundColor: '#a4aba4'}}
					        image={require('../static/images/kamini-kaushal-3.jpeg')}
					        alt="kamini kaushal"
					      />
						      <CardHeader 
						      title="About me"
						      subheader="I am Kamini Kaushal, wife of Mr. Kaushal Kumar. I live in Madhubani, Bihar and was
						          	born in Bishaul village in the district of Madhubani (Mithila)">
						      </CardHeader>
						      <Divider light={true}/>
					        
					        <Typography variant="h6">
					        	My Village
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					            My village is a historical place which has religious value too. 
								According to the elders, Lord Rama and Lakshman with their 
								guru Viswamitra stayed there when they were in the way of Sita’s Swayambar from 
								Ayodhya to Janakpur. Rama and Lakshman stayed in a temple called <i>Asthan</i> which was discovered while digging a pond. As my 
								childhood has been spent in such a religious and traditional environment, I 
								got an inclination towards traditional art.
					        </Typography>
					        <Divider light={true} sx={{pb: 2}}/>

					        <Typography variant="h6">
					        	My Motivation
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					          After marriage I got the same environment in my in-laws' village <i>Salempur</i>. 
                                My mother in Law late Tarini Devi was a master craftswoman of 
								Madhubani art. This gave me the inspiration to start my journey in Papier Mache craft. I chose this art as it doesn't have only a traditional value but 
								it also helps to convert environmental waste (paper) to art.
					        </Typography>
					        <Divider light={true} sx={{pb: 2}}/>

					        <Typography variant="h6">
					        	My Art Journey
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					           <i>The Development Commissioner (handicrafts), Govt. of India, </i>and <i>Paramparik Karigar</i> has 
					           helped me a lot in my journey by providing me opportunities to exhibit my crafts in many exhibitions.
								My crafts has been a part of many exhibitions in different cities like Dehradun, Lucknow, 
								Delhi, Pune, Bengaluru, Kolkata, Mumbai and others. I got 
								a lot of appreciation for this effort and also successfully delivered many orders for 
								different buyers.
					        </Typography>
					        <Divider light={true} sx={{pb: 2}}/>

					        <Typography variant="h6">
					        	Guiding Others
					        </Typography>
					        <Typography variant="body" color="text.secondary">
					        I want to make people aware about this art. For this, I have given many workshops in different higher institutes 
								and schools of every age group.  I am trying to grow this craft and enhance its popularity to a higher level.
							</Typography>
					      </CardContent>
					    </Card>
			    	</Grid>
		</>
		);
}

export default ProfileComponent;