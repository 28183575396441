import { AppBar, Box, Button, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Tooltip, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CallIcon from '@mui/icons-material/Call';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { useState } from 'react';
import profilePic from '../static/images/kamini-kaushal.png';
import bgPic from '../static/images/bg.png';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import './animation.css'

const drawerWidth = 170;

 const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: 'aliceblue'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  paddingLeft: '2px',
  backgroundColor: 'aliceblue',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});
    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const MainComponent = () => {
	
	const [drawerOpen, setDrawerOpen] = useState(true);
	const navLinkStyle = ({isActive}) => {
						 	return {
    backgroundColor: isActive ? "lightgray" : 'aliceblue'
  }}


	return (
		<>
			<Box sx ={{display: 'flex', backgroundImage: `url(${bgPic})`, backgroundAttachment: 'fixed', backgroundSize: 'auto 350px', backgroundPositionX: 'right', backgroundPositionY: '150px', backgroundRepeat: 'no-repeat', minHeight: '600px', backgroundColor: 'lightgrey'}}>
				<AppBar
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
					<Toolbar>
						<Button variant="secondary"
						onClick={()=>setDrawerOpen(!drawerOpen)}>
							{drawerOpen ? <ArrowBackIcon/> : <MenuIcon/>}
						</Button>
						<Button to='/' variant="secondary" component={Link} onClick={()=>setDrawerOpen(true)} sx={{textTransform: 'none'}}>
							<Typography variant="h6"  color='white' sx={{textShadow: '2px 2px 4px #000'}}>
								Papier Mache Craft
							</Typography>
						</Button>
						
						<Typography variant="body2" sx={{ml: 3}}>
							by Kamini Kaushal
						</Typography>
					</Toolbar>
				</AppBar>
				
				<Drawer 
				variant="permanent"
				open={drawerOpen}
				onClose={()=>setDrawerOpen(!drawerOpen)}
				anchor = "left"
				sx={{textAlign: 'center'}}
				>
					<Toolbar/>
					<Box sx={{pt: 1.5, backgroundColor: 'aliceblue'}}>
						<img src={profilePic} height = "60px" alt="Kamini Kaushal"/>
					</Box>
					<Divider/>
					<Box>
						<List onClick={()=>setDrawerOpen(false)}>
						  <ListItemButton component={NavLink} style={navLinkStyle} to="/">
			                <ListItemIcon>
			                { (!drawerOpen && 
			                	<Tooltip title="Home" placement="right">
			               			<HomeIcon/>
			               		</Tooltip>) || <HomeIcon/>}
			                </ListItemIcon>
			                <ListItemText primary="Home" />
			              </ListItemButton>
			              <ListItemButton component={NavLink} style={navLinkStyle} to="/profile">
			                <ListItemIcon>
			               		{ (!drawerOpen && 
			                	<Tooltip title="About me" placement="right">
			               			<PersonIcon/>
			               		</Tooltip>) || <PersonIcon/>}
			                </ListItemIcon>
			                <ListItemText primary="About me" />
			              </ListItemButton>
			              	
			              <ListItemButton component={NavLink} style={navLinkStyle} to="/gallery">
			                <ListItemIcon>
			               		{ (!drawerOpen && 
			                	<Tooltip title="Art Gallery" placement="right">
			               			<ArtTrackIcon/>
			               		</Tooltip>) || <ArtTrackIcon/>}
			                </ListItemIcon>
			                <ListItemText primary="Art Gallery" />
			              </ListItemButton>

						 <ListItemButton component={NavLink} style={navLinkStyle} to="/awards" >
						    <ListItemIcon>
						   		{ (!drawerOpen && 
			                	<Tooltip title="Awards" placement="right">
			               			<MilitaryTechIcon/>
			               		</Tooltip>) || <MilitaryTechIcon/>}
						    </ListItemIcon>
						    <ListItemText primary="Awards" />
						  </ListItemButton>

						  <ListItemButton component={NavLink} style={navLinkStyle} to="/workshops" >
						    <ListItemIcon>
						   		{ (!drawerOpen && 
			                	<Tooltip title="Workshops" placement="right">
			               			<Diversity3Icon/>
			               		</Tooltip>) || <Diversity3Icon/>}
						    </ListItemIcon>
						    <ListItemText primary="Workshops" />
						  </ListItemButton>

						  <ListItemButton component={NavLink} style={navLinkStyle} to="/contact">
						    <ListItemIcon>
						   		{ (!drawerOpen && 
			                	<Tooltip title="Contact" placement="right">
			               			<CallIcon/>
			               		</Tooltip>) || <CallIcon/>}
						    </ListItemIcon>
						    <ListItemText primary="Contact" />
						  </ListItemButton>	
						</List>
					</Box>
				</Drawer>

				<Grid sx={{ flexGrow: 1, p: 1 }} p={1.1} onClick={()=>setDrawerOpen(false)} container item xs={12} sm={7}>
						<Grid item xs={12}>
							<Toolbar/>
							<Outlet />
						</Grid>
				</Grid>
				
			</Box>
		</>
		);
}

export default MainComponent;