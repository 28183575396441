import { Card, CardContent, CardHeader, CardMedia, Grid } from '@mui/material';

const AwardsComponent = () => {
	const awards = require('../config/awards.json');
	const imagePrefix = 'static/images/awards/';
	
	
	return (
	<>
			<Card className='animated'>
				<CardContent style={{backgroundColor: 'aliceblue'}}>
				<CardHeader title="Awards & Achievements">
					        </CardHeader>
			<Grid container spacing={2}>
				{awards.map((award, idx) => (
					<Grid item xs={12} sm={6} key={idx}>
						<Card sx={{height: '100%'}}>
					      <CardMedia
					        component="img"
					        height="240"
					        image={require('../' + imagePrefix + award.image)}
					        alt="award image"
					        sx = {{objectFit: "contain"}}
					      />
					      <CardContent>
					        <CardContent>
					        <CardHeader title={award.heading} subheader={award.description}>
					        </CardHeader>
					      </CardContent>
					      </CardContent>
					    </Card>
			    	</Grid>
					))}
		    </Grid>
		    </CardContent>
				</Card>
		</>
		)
}

export default AwardsComponent;