import { Box, Button, Card, CardActions, CardContent, CardHeader,CardMedia,Divider, Grid, Link as MuiLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';


const WelcomeComponent = () => {
	return (
			<>

			
			
				<Grid item className='animated'>
					<Card sx={{textAlign: 'justify', backgroundColor: 'aliceblue'}}>
				      
				      <CardContent><CardMedia component="img" height="240" sx = {{objectFit: "contain", backgroundColor: '#f12a2f'}} image={require
					      ("../static/images/kamini-kaushal-2.jpg")} alt="Profile Picture"/> 
				      <CardHeader title="About this website"
				  		>
				      </CardHeader>
				       <Typography variant="body" color="text.secondary" fontSize= '1.1rem'> 
				      Kamini Kaushal is a Master Craft Person and she has been practising Papier Mache Craft for more than 30 years. This website
				      holds information about <MuiLink to='/profile' component={Link} underline='hover'>
				      her
				      </MuiLink>

				      , her <MuiLink to='/gallery' component={Link} underline='hover'>
				      craft samples
				      </MuiLink>

				      , the <MuiLink to='/awards' component={Link} underline='hover'>recognitions</MuiLink> that she has earned in this journey and her attempt to spread awareness about this craft.

				      <p>
				      	It is also an attempt to create a contact channel so that art lovers can contact her. 
				      </p>
				      </Typography>
				      <Divider light={true}/> 
				      	<Box>
							<Typography variant="h6">
				        	The Art
				        </Typography>
				      <Typography variant="body" color="text.secondary"> Papier Mache Craft is a
				      way to mold idols/sculptures with the use of paper. This is hand made craft, which is very
				      valuable in art & craft field. Waste paper, multani mitti & fevicol are the only raw
				      materials required for this art.
				      </Typography>
				      <Divider/>

				      <Typography variant="h6">
				        	The Process
				        </Typography>
				        <Typography variant="body" color="text.secondary">
				      First, waste paper is kept in water for a few days so that it dissolves in the water. 
				      Then, it is beaten by a wooden slab and mixed with other raw materials like multani
				      mitti, darad maida to prepare the dough. Then, it is given the required shape with the 
				      help of hands. It can be given any shape like a plate, or a sculpture or any other
				      creative thing. Samples can be found in the art gallery.

				      </Typography> 
				      	
				      		</Box>
				      	<CardActions>
				      		<Button size="medium" variant="outlined" color="primary" component={Link} to="/artDetails">Learn more about the art</Button>
				      	</CardActions>

				      </CardContent> 

				      </Card> </Grid> 

		</>
		);
}

export default WelcomeComponent;