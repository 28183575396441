import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';


const ContactComponent = () => {
	return (
			<>
			
				<Grid item xs={12} className='animated' >
					<Card sx={{backgroundColor: 'aliceblue'}}>
				      
				      <CardContent>
				        <Typography gutterBottom variant="h6" component="div">
				          Contact to order
				        </Typography>
				        <Typography variant="body" color="text.secondary" component="div">
				        KAMINI KAUSHAL (M.C.P.)
				        </Typography>


				         <Typography variant="body" color="text.secondary" component="div">
				         Contact no. : +919431654082
				         </Typography>


				         <Typography variant="body" color="text.secondary" component="div">
				          Email  Id: kaushalkamini.1779@gmail.com
				          </Typography>

				          <Divider/>
				          <Typography variant="body" color="text.secondary" component="div">
				          Address:
				          </Typography>
				          

				          <Typography variant="body" color="text.secondary" component="div">
				          Hanuman bag, Gaushala Road, 
						
						</Typography>
						<Typography variant="body" color="text.secondary" component="div">
							Gumti no.- 13,Madhubani, Bihar-847211
						</Typography>
						<Typography variant="body" color="text.secondary" component="div">
							For more queries and placed orders, contact on this number: +919431654082 (Kaushal Kumar)
				        </Typography>
				      </CardContent>
				    </Card>
		    	</Grid>

		</>
		);
}

export default ContactComponent;